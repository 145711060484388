<template>
    <v-sheet>
        <v-container fluid style="background-color: #f3f3f3" class="">
            <!--<router-view></router-view>-->
            <v-row class="my-10 align-center justify-center mx-auto">
                <!-- <v-col cols="9" xl="3" lg="3" md="5" sm="5" class="d-flex justify-center">
                    <v-img max-width="330" contain class="gala mx-auto" src="../assets/img-rotisserie/grill/d_box_header.png">
                    </v-img>
                </v-col> -->
    
                <v-col cols="11" xl="5" lg="5" md="5" sm="7" id="produit">
                    <p class="
                                              text-h6 text-xl-h2 text-lg-h3 text-md-h text-sm-h4 text-
                                                        font-weight-bold
                                            ">
                        Dorez-vous la pilule avec nos Rôtissoires !
                    </p>
                    <p class=" primary--text text-body-2 text-lg-h6 text-md-h6 text-sm-body-1 font-weight-regular">
                        Cuisson incomparable : grâce aux programmes « Smart Cooking Function® » par association de l’air chaud pulsé et des infrarouges directs, qui permet d’associer « le moelleux et l’aspect coloré/croustillant des produits ».
                    </p>
                    <p class="primary--text">
                        A partir de
                        <strong class="rotisserie--text text-body-2 text-lg-h4 text-md-h6 text-sm-h6 font-weight-bold">4.00€ HT/jour</strong
                                            >
                                            tout inclus.
                                          </p>
                                
                                          <!-- <a class="rotisserie--text font-weight-bold" href="#"
                                            >En savoir plus
                                            <v-icon color="rotisserie" class="font-weight-bold"
                                              >mdi-chevron-right</v-icon
                                            ></a
                                          > -->
                                
                             
        
                                          <v-form class="mt-3" @submit.prevent="sendMail('Souhaite être recontacté')" v-model="valid" lazy-validation>
                                            <v-row class="ustify-center">
                                                <v-col cols="10" xl="6" lg="6" md="6" sm="6">
                                                    <v-text-field v-model="formulaire.nomPrenom" class="text-lg-body-1" label="Nom et prénom" solo required :rules="nomPrenomRules"></v-text-field>
                        
                                                    <v-text-field v-model="formulaire.numeroTelephone" label="Numéro de téléphone" solo :rules="numeroTelephoneRules" required></v-text-field>
                                                </v-col>
                                                <v-col cols="10" xl="6" lg="6" md="6" sm="6" class="text-xl-left text-lg-left text-center">
                                                    <v-text-field v-model="formulaire.adresseMail" label="Adresse email" solo :rules="adresseMailRules" required></v-text-field>
                                                    <v-btn color="rotisserie" large class="white--text text-uppercase" type="submit">
                                                        être recontacté
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                
                                    <v-container fluid class="my-16">
                                      <v-row class="py-16 justify-center">
                                        <v-col cols="12" xl="7" lg="8" md="9" sm="12"  class="text-center">
                                          <p class="text-h6 text-lg-h4 text-md-h4 text-sm-h5 font-weight-bold">Fonctionnalités et aperçu</p>
                                          <p class="primary--text text-lg-button font-weight-light mx-4 mx-xl-16 mx-lg-16 mx-md-16 mx-sm-4">
                                            Le choix parfait pour les endroits à forte consommation tels que les
                                            hôtels, les restaurants ou les supermarchés. Équipé d'un système
                                            d'alimentation automatique, il est capable de presser des oranges,
                                            des mandarines et des citrons. Il permet à vos clients de s'offrir
                                            une option de boisson fraîche, naturelle, saine et riche en
                                            vitamines.
                                          </p>
                                        </v-col>
                                      </v-row>
                                      <v-row class="d-flex align-center justify-center d-inline-block ">
                                        <v-col
                                          cols="12"
                                              xl="3"
                                              lg="5"
                                              md="5"
                                              sm="9"
                                          class="text-center px-sm-16 px-xl-12 order-lg-1"
                                        >
                                          <div>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="50"
                                              height="50"
                                              fill="#d32f2f"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
                                              />
                                              <path
                                                d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
                                              />
                                            </svg>
                                
                                            <p class="text-xl-h6 text-lg-h6 text-md-body-2 text-sm-body-2">ROBUSTE</p>
                                            <p class="primary--text mx-4 mx-xl-16 mx-lg-16 mx-md-16 mx-sm-4  text-caption text-xl-body-1 text-lg-body-1 text-md-body-2 text-sm-body-2">
                                              Conçue pour durer, les matériaux de qualités utilisées assurent une grande
                                              longévité à nos machines.
                                            </p>
                                          </div>
                                        </v-col>
                                        <v-col cols="12"
                                              xl="3"
                                              lg="5"
                                              md="5"
                                              sm="9"
                                          class="text-center px-sm-16 px-xl-8 order-lg-3 order-md-3"> 
                                          <div class="py-16">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="50"
                                              height="50"
                                              fill="#d32f2f"
                                              class="bi bi-droplet"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10a5 5 0 0 0 10 0c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"
                                              />
                                            </svg>
                                
                                            <p class="text-xl-h6 text-lg-h6 text-md-body-2 text-sm-body-2">NETTOYAGE AUTOMATIQUE</p>
                                            <p class="primary--text mx-4 mx-xl-16 mx-lg-16 mx-md-16 mx-sm-4  text-caption text-xl-body-1 text-lg-body-1 text-md-body-2 text-sm-body-2">
                                              Economisez du temps et de la main d'oeuvre et offrez un haut
                                              niveau d'hygiène grâce au système de nettoyage automatique en
                                              option sur nos machines.
                                            </p>
                                          </div>
                                        </v-col>
                                       <!-- <v-col
                                          cols="7"
                                          xl="3"
                                          lg="4"
                                          md="2"
                                          sm="4"
                                          class="d-flex justify-center"
                                          id="fonction"
                                        >
                                          <v-img
                                            width="300"
                                            max-height="350"
                                            contain
                                            src="../assets/img-rotisserie/grill/d_box_header.png"
                                          ></v-img>
                                        </v-col> -->
                                        <v-col
                                          cols="12"
                                          xl="3"
                                          lg="5"
                                          md="5"
                                          sm="9"
                                          class="justify-center text-center px-sm-16 order-lg-2"
                                        >
                                          <div class="pb-5">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="50"
                                              height="50"
                                              fill="#d32f2f"
                                              class="bi bi-stopwatch"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"
                                              />
                                              <path
                                                d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"
                                              />
                                            </svg>
                                
                                            <p class="text-xl-h6 text-lg-h6 text-md-body-2 text-sm-body-2">RAPIDE</p>
                                            <p class="primary--text mx-4 mx-xl-16 mx-lg-16 mx-md-16 mx-sm-4  text-caption text-xl-body-1 text-lg-body-1 text-md-body-2 text-sm-body-2">
                                              Bénéficiez d'un des équipement les plus rapide du marché. Il peut
                                              presser jusqu'à 200 litres par heure !
                                            </p>
                                          </div>
                                          </v-col>
                                          <v-col cols="12"
                                              xl="3"
                                              lg="5"
                                              md="5"
                                              sm="9"
                                          class="text-center px-sm-16 order-lg-4 order-md-4">
                                          <div class="py-16">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="50"
                                              height="50"
                                              fill="#d32f2f"
                                              class="bi bi-sliders"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"
                                              />
                                            </svg>
                                
                                            <p class="text-xl-h6 text-lg-h6 text-md-body-2 text-sm-body-2">PERSONNALISABLES</p>
                                            <p class="primary--text mx-4 mx-xl-16 mx-lg-16 mx-md-16 mx-sm-4  text-caption text-xl-body-1 text-lg-body-1 text-md-body-2 text-sm-body-2">
                                              Grâce à un pannel d'options, nos machines s'adaptent à vos besoins
                                              et répondent parfaitement aux attentes de vos clients.
                                            </p>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                    <v-container fluid>
                                      <v-row class="py-16">
                                        <v-col cols="12" xl="7" lg="7" md="10" sm="12"  class="mx-auto text-center">
                                          <p class="text-h6 text-xl-h4 text-md-h4 text-sm-h5 font-weight-bold">
                                            Une offre et un prix adaptée à la taille de votre commerce
                                          </p>
                                          <p
                                            class="
                                              primary--text
                              text-lg-h6 text-md-h6 text-sm-h6 text-body-1
                              font-weight-light
                              mx-xl-16 mx-lg-16 mx-md-16 mx-1
                                            "
                                          >
                                            Une gamme simple et pensée pour répondre à vos besoins..
                                          </p>
                                        </v-col>
                                      </v-row>
                                      <v-row class="justify-center pb-16 align-center">
                                        <v-col cols="12" xl="2" lg="6" md="6" sm="12">
                                          <v-card
                                            elevation="9"
                                            class="mx-auto my-12"
                                            max-width="340"
                                            id="tarif"
                                          >
                                            <v-img
                                              class="py-10 mx-auto zoomable-"
                                              contain
                                              width="200"
                                              src="../assets/img-rotisserie/grill/d_box.png"
                                            ></v-img>
                                
                                            <v-card-text class="text-center">
                                              <div class="black--text text-body-1 font-weight-bold">
                                                Rôtissoire à balancelles
                                              </div>
                                              <div class="black--text text-body-1 font-weight-bold">
                                                D-BOX 
                                              </div>
                                              <div class="primary--text my-4 text-caption">A PARTIR</div>
                                              <div class="my-4 text-h5 text-xl-h4 text-lg-h4 text-sm-h4 rotisserie--text"><!--??€ HT/jours*--></div>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Capacité: 16 Vol (selon le poids)</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Puissance: 5,4 kW</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">
                                                Nombre de balancelles: 4 balancelles inox
                                              </div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Dimensions: 980 x 950 x 950 mm</div>
                                            </v-card-text>
                                
                                            <v-card-actions class="justify-center">
                                              <v-btn
                                                color="rotisserie"
                                                large
                                                class="white--text text-uppercase"
                                                @click="$vuetify.goTo('#contact')"
                                              >
                                                commander
                                              </v-btn>
                                            </v-card-actions>
                                            <v-divider class="mx-4 my-4"></v-divider>
                                            <v-card-title class="justify-center"><a class="text-xl-body-1 text-lg-body-2 rotisserie--text font-weight-bold" href="./pdf/DBOX.pdf" target="_blank">voir la fiche technique</a></v-card-title>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" xl="2" lg="6" md="6" sm="12">
                                          <v-card
                                            elevation="9"
                                            class="mx-auto my-12 justify-center"
                                            max-width="374"
                                          >
                                            <v-img
                                              class="mx-auto zoomable"
                                              contain
                                              width="190"
                                              src="../assets/img-rotisserie/grill/d_box_marchand.png"
                                            ></v-img>
                                
                                            <v-card-text class="text-center">
                                              <div class="black--text text-body-1 font-weight-bold">
                                                Rôtissoire à balancelles
                                              </div>
                                              <div class="black--text text-body-1 font-weight-bold">
                                                D-BOX MARCHAND
                                              </div>
                                              <div class="primary--text my-4 text-caption">A PARTIR</div>
                                              <div class="my-4 text-h5 text-xl-h4 text-lg-h4 text-sm-h4  rotisserie--text "><!--??€ HT/jours*--></div>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Capacité: 24 Vol, 11 grilles</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Puissance: 9,5 kW</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">
                                                Nombre de balancelles: 6 balancelles inox
                                              </div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Dimensions: 1150 x 975 x 1065</div>
                                            </v-card-text>
                                
                                            <v-card-actions class="justify-center">
                                              <v-btn
                                                color="rotisserie"
                                                large
                                                class="white--text text-uppercase"
                                                @click="$vuetify.goTo('#contact')"
                                              >
                                                commander
                                              </v-btn>
                                            </v-card-actions>
                                            <v-divider class="mx-4 my-4"></v-divider>
                                            <v-card-title class="justify-center"><a class="text-xl-body-1 text-lg-body-2 rotisserie--text font-weight-bold" href="./pdf/DBOX.pdf" target="_blank">voir la fiche technique</a></v-card-title>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" xl="2" lg="6" md="6" sm="12" class="order-xl-5">
                                          <v-card
                                            elevation="9"
                                            class="mx-auto my-12 justify-center"
                                            max-width="374"
                                          >
                                            <v-img
                                              class="py-10 mx-auto zoomable"
                                              contain
                                              width="200"
                                              src="../assets/img-rotisserie/grill/Nantaise.png"
                                            ></v-img>
                                
                                            <v-card-text class="text-center">
                                              <div class="black--text text-body-1 font-weight-bold">
                                                Rôtissoire à balancelles
                                              </div>
                                              <div class="black--text text-body-1 font-weight-bold">
                                                NANTAISE 
                                              </div>
                                              <div class="primary--text my-4 text-caption">A PARTIR</div>
                                              <div class="my-4 text-h5 text-xl-h4 text-lg-h4 text-sm-h4  rotisserie--text"><!--??€ HT/jours*--></div>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Capacité: 12 volailles</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Puissance: 6.32 Kw</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">
                                                Nombre de balancelles: 4 balancelles inox
                                              </div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Dimensions: 870 x 790 x 730 mm</div>
                                            </v-card-text>
                                
                                            <v-card-actions class="justify-center">
                                              <v-btn
                                                color="rotisserie"
                                                large
                                                class="white--text text-uppercase"
                                                @click="$vuetify.goTo('#contact')"
                                              >
                                                commander
                                              </v-btn>
                                            </v-card-actions>
                                            <v-divider class="mx-4 my-4"></v-divider>
                                            <v-card-title class="justify-center"><a class="text-xl-body-1 text-lg-body-2 rotisserie--text font-weight-bold" href="./pdf/NANTAISE.pdf" target="_blank">voir la fiche technique</a></v-card-title>
                                          </v-card>
                                        </v-col>

                                        <v-col cols="12" xl="2" lg="6" md="6" sm="12" class="order-xl-3">
                                          <v-card
                                            elevation="9"
                                            class="mx-auto my-12 justify-center"
                                            max-width="374"
                                          >
                                            <v-img
                                              class="py-11 mx-auto zoomable"
                                              contain
                                              width="220"
                                              src="../assets/img-rotisserie/grill/steambox.png"
                                            ></v-img>
                                
                                            <v-card-text class="text-center">
                                              <div class="black--text text-body-1 font-weight-bold">
                                                Rôtissoire à balancelles
                                              </div>
                                              <div class="black--text text-body-1 font-weight-bold">
                                                STEAMBOX
                                              </div>
                                              <div class="primary--text my-4 text-caption">A PARTIR</div>
                                              <div class="my-4 text-h5 text-xl-h4 text-lg-h4 text-sm-h4  rotisserie--text"><!--??€ HT/jours*--></div>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Capacité: 24 Vol, 11 grilles</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Puissance: 9,5 kW</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">
                                                Nombre de balancelles: 6 balancelles inox
                                              </div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Dimensions: 1150 x 975 x 1065</div>
                                            </v-card-text>
                                
                                            <v-card-actions class="justify-center">
                                              <v-btn
                                                color="rotisserie"
                                                large
                                                class="white--text text-uppercase"
                                                @click="$vuetify.goTo('#contact')"
                                              >
                                                commander
                                              </v-btn>
                                            </v-card-actions>
                                            <v-divider class="mx-4 my-4"></v-divider>
                                            <v-card-title class="justify-center"><a class="text-xl-body-1 text-lg-body-2 rotisserie--text font-weight-bold" href="./pdf/STEAMBOX.pdf" target="_blank">voir la fiche technique</a></v-card-title>
                                          </v-card>
                                        </v-col>
                                        
                                
                                        <v-col cols="12" xl="2" lg="8" md="8" sm="12" >
                                          <v-card
                                            elevation="9"
                                            class="mx-auto my-1 justify-center"
                                            max-width="374"
                                          >
                                            <v-img
                                              class="py-10 mx-auto zoomable-"
                                              contain
                                              width="110"
                                              src="../assets/img-rotisserie/grill/neobox_plein_800h.png"
                                            ></v-img>
                                
                                            <v-card-text class="text-center">
                                              <div class="black--text text-body-1 font-weight-bold">
                                                Rôtissoire à balancelles
                                              </div>
                                              <div class="black--text text-body-1 font-weight-bold">
                                                NEOBOX
                                              </div>
                                              <div class="primary--text my-4 text-caption">A PARTIR</div>
                                              <div class="my-4 text-h5 text-xl-h4 text-lg-h4 text-sm-h4  rotisserie--text"><!--??€ HT/jours*--></div>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Capacité: 12 Vol (selon le poids)</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Puissance: 6.32 Kw</div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">
                                                Nombre de balancelles: 9 balancelles inox
                                              </div>
                                              <v-divider class="mx-4 my-4"></v-divider>
                                              <div class="text-body-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1">Dimensions: 870 x 790 x 730 mm</div>
                                            </v-card-text>
                                
                                            <v-card-actions class="justify-center">
                                              <v-btn
                                                color="rotisserie"
                                                large
                                                class="white--text text-uppercase"
                                                @click="$vuetify.goTo('#contact')"
                                              >
                                                commander
                                              </v-btn>
                                            </v-card-actions>
                                            <v-divider class="mx-4 my-4"></v-divider>
                                            <v-card-title class="justify-center"><a class="text-xl-body-1 text-lg-body-2 rotisserie--text font-weight-bold" href="./pdf/STEAMBOX.pdf" target="_blank">voir la fiche technique</a></v-card-title>
                                          </v-card>
                                        </v-col>
                                        
                                        <v-col cols="12" class="text-center order-5">
                                          <p class="text-h6 pt-5 color-black">
                                            <i
                                              >* Cette offre est soumise à la signature d’un contrat de
                                              location.<br />Sous réserve de l’accord du comité des
                                              engagement.</i
                                            >
                                          </p>
                                        </v-col>
                                        
                                      </v-row>
                                    </v-container>
                                
                                    <v-container fluid style="background-color: #f3f3f3" class="py-16">
                                      <v-row class="d-flex align-center justify-sm-center">
                                        <v-col cols="9"
                                      xl="3"
                                      lg="3"
                                      md="5"
                                      sm="5">
                                          <v-img
                                            contain
                                            max-width="400"
                                            src="../assets/img-rotisserie/butcher2.png"
                                          ></v-img>
                                        </v-col>
                                
                                        <v-col cols="11"
                                      xl="5"
                                      lg="4"
                                      md="4"
                                      sm="10">
                                          <p class="text-h6 text-lg-h4 text-sm-h5 font-weight-bold">
                                            Craquez pour ces Rôtissoires à bon prix!
                                          </p>
                                          <p
                                            class="primary--text text-lg-body-1 font-weight-light pr-16"
                                            style="line-height: 30px"
                                          >
                                            Nous vous offrons un service allant de la courte durée (à la
                                            journée) jusqu'à la longue durée, payez pour ce dont vous avez
                                            réellement besoin et ne payez que selon votre usage.
                                          </p>
                                          
                                            <ul v-for="(item, i) in Croissant" :key="i" class="py-3">
                                           
                                                 <li class="primary--text font-weight-light"
                                                  v-text="item.text"></li> 
                                                
                                            </ul>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                    <v-container fluid class="py-16">
                                      <v-row class="d-flex align-item justify-center">
                                        <v-col
                                          class="order-2 order-xl-1 order-lg-1 order-md-1 order-sm-2"
                                          cols="11"
                                          xl="4"
                                          lg="4"
                                          md="4"
                                          sm="10"
                                        >
                                          <p class="text-h6 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 font-weight-bold">
                                            Sublimez votre entreprise comme vous sublimez vos plats !
                                          </p>
                                          <p
                                            class="primary--text text-lg-body-1 font-weight-light pr-16"
                                            style="line-height: 30px"
                                          >
                                            Avec PULSE Services, optimiser votre bilan RSE en optant pour la
                                            mise en avant de l’économie circulaire et une consommation raisonné
                                            des équipements. Avec la transformation sur place, diminuons nos
                                            émissions CO2.
                                          </p>
                                          <ul v-for="(item, i) in Pain" :key="i" class="py-3">
                                           
                                                 <li class="primary--text font-weight-light"
                                                  v-text="item.text"></li> 
                                                
                                            </ul>
                                        </v-col>
                                
                                        <v-col
                                          class="order-1 order-xl-2 order-lg-2 order-md-2 order-sm-1"
                                          cols="9"
                                          xl="3"
                                          lg="3"
                                          md="5"
                                          sm="5"
                                        >
                                          <v-img
                                            contain
                                            max-width="400"
                                            src="../assets/img-rotisserie/board-with-chicken-rosemary-white.png"
                                          ></v-img>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                
                                    <v-container fluid class="contactez">
                                      <v-row class="d-flex justify-center my-16">
                                        <v-col cols="8" class="text-center">
                                          <p
                                            class="
                                              white--text
                                      text-body-1
                                      text-xl-display-1
                                      text-lg-display-1
                                      text-md-h4
                                      text-sm-h5
                                      font-weight-bold
                                            "
                                          >
                                            Restez connectez pour des nouvelles croustillantes à souhait
                                          </p>
                                          <v-btn
                                            color="rotisserie"
                                            large
                                            class="white--text text-uppercase mt-8"  @click="$vuetify.goTo('#contact')"
                                          >
                                            contactez-nous
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                
                                     <v-container fluid class="backgroundGrey py-16" id="avis">
                          <v-carousel
                            cycle
                            :hide-on-leave="true"
                            :show-arrows="false"
                            :hide-delimiters="true"
                            interval="6000"
                            height="180%"
                            hide-delimiter-background
                          >
                            <v-carousel-item
                              class="mt-16 mb-16"
                              v-for="(testimoniale, i) in testimoniales"
                              :key="i"
                            >
                              <v-sheet class="d-flex justify-center backgroundGrey" width="100%">
                                <v-row class="d-flex justify-center">
                                  <v-col cols="10" xl="5" lg="5" md="8" sm="8">
                                    <v-card elevation="7" class="my-10 py-10">
                                      <v-row
                                        class="fill-height mx-auto"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-col
                                          cols="7"
                                          xl="5"
                                          lg="5"
                                          md="4"
                                          sm="4"
                                          class="d-flex justify-center"
                                        >
                                          <v-img
                                            contain
                                            class="logo d-block carrousel-logo"
                                            :src="testimoniale.src"
                                            max-width="200"
                                          />
                                        </v-col>
                                        <v-col
                                          cols="9"
                                          xl="6"
                                          lg="6"
                                          md="6"
                                          sm="6"
                                          class="d-flex flex-column justify-left"
                                        >
                                          <p
                                            class="
                                              primary--text
                                              text-body-2
                                              text-xl-body-1
                                              text-lg-body-1
                                              text-md-body-1
                                              text-sm-body-2
                                            "
                                          >
                                            {{ testimoniale.text }}
                                          </p>
                                          <p
                                            class="
                                              font-weight-bold
                                              text-body-2
                                              text-xl-body-1
                                              text-lg-body-1
                                              text-md-body-1
                                              text-sm-body-2
                                            "
                                          >
                                            {{ testimoniale.name }}
                                          </p>
                                          <p
                                            class="
                                              rotisserie--text
                                              font-weight-bold
                                              text-body-2
                                              text-xl-body-1
                                              text-lg-body-1
                                              text-md-body-1
                                              text-sm-body-2
                                            "
                                          >
                                            {{ testimoniale.job }}
                                          </p>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-sheet>
                            </v-carousel-item>
                          </v-carousel>
                        </v-container>
                                    
                                
                                    <v-container fluid class="pt-16 pb-16">
                                      <v-row class="justify-center text-center">
                                        <v-col cols="12" sm="9">
                                          <p class="text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4 font-weight-medium">Vous hésitez encore ?</p>
                                          <p class="text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4 font-weight-medium">Eux, ils ont déjà décidé !</p>
                                          <p class="primary--text text-body-1 text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6">
                                            Pour la gestion de leurs équipements, ils font déjà appel à la
                                            première Société de Service en Cycle de Vie !
                                          </p>
                                        </v-col>
                                        <v-col cols="12" class="justify-center text-center pa-0">
                                          
                                
                                          <v-carousel
                                            class="mt-9 mb-16"
                                            cycle
                                            :show-arrows="false"
                                            interval="5000"
                                            height="180"
                                            hide-delimiter-background
                                          >
                                            <v-carousel-item v-for="(logo, i) in logos" :key="i">
                                              <v-sheet class="rotisserie" height="60%">
                                                <v-row class="fill-height" align="center" justify="center">
                                                  <v-img
                                                    class="d-block carrousel-logo"
                                                    :src="logo.src"
                                                    height="auto"
                                                  />
                                                </v-row>
                                              </v-sheet>
                                            </v-carousel-item>
                                          </v-carousel>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                               <v-container fluid class="justify-center" id="contact">
              <v-row class="justify-center mx-auto pb-8 text-center">
                <v-col cols="12" class="text-center">
                  <p class="text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 font-weight-medium pb-8">
                    Un renseignement, un devis, nous restons <br />à votre disposition.
                  </p>
                  <p class="primary--text text-h6 text-xl-h5 text-lg-h5 text-md-h5 text-sm-h6 font-weight-medium pb-8">
                    Laissez-nous vos cordonnées, nous vous recontactons.
                  </p>
                </v-col>
        
            
                  <v-col cols="12" xl="4" lg="5" md="7" sm="9">
                    <v-form
                      class="mt-3"
                      @submit.prevent="sendMail"
                      v-model="valid"
                      lazy-validation
                    >
                      <v-text-field
                        label="Nom et prénom"
                        v-model="formulaire.nomPrenom"
                        solo
                        required
                        :rules="nomPrenomRules"
                      ></v-text-field>

                      <v-text-field
                        label="Adresse postale"
                        v-model="formulaire.adresse"
                        solo
                        required
                        :rules="adresseRules"
                      ></v-text-field>
        
                      <v-text-field
                        v-model="formulaire.numeroTelephone"
                        label="Numéro de téléphone"
                        solo
                        :rules="numeroTelephoneRules"
                        required
                      ></v-text-field>
        
                      <v-text-field
                        v-model="formulaire.adresseMail"
                        label="Adresse email"
                        solo
                        :rules="adresseMailRules"
                        required
                      ></v-text-field>
        
                      <v-textarea
                        v-model="formulaire.message"
                        solo
                        name="input-7-4"
                        label="votre message"
                        :rules="messageRules"
                        required
                        :counter="200"
                      ></v-textarea>
        
                      <v-btn
                        color="rotisserie"
                        type="submit"
                        large
                        class="white--text text-uppercase"
                      >
                        être recontacté
                      </v-btn>
                    </v-form>
                  </v-col>
        
              </v-row>
            </v-container>
                                  </v-sheet>
</template>

<script>
import emailjs from "emailjs-com";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
    name: "App",
    mixins: [validationMixin],

    validations: {
        email: { required, email },
    },
   
    data() {
        return {
            model: null,
            dialog: false,
            tab: null,
            drawer: null,

            // Régles des champs formulaires //
            valid: true,

            nomPrenomRules: [
                (v) => !!v || "Vous devez saisir un nom et prénom",
                (v) =>
                (v && v.length <= 30) ||
                " Ce champ doit comporter moins de 30 caractères",
            ],
            adresseRules: [
                (v) => !!v || "Vous devez saisir une adresse postal",
                (v) =>
                /.([0-9]*) ?([a-zA-Z]*) ?([0-9]{5}) ?([a-zA-Z]*)/.test(v) || "Veuillez saisir une adresse postal valide",
            ],

            numeroTelephoneRules: [
                (v) => !!v || "Vous devez saisir un numéro de téléphone",
                (v) =>
                /^((\+|00)33\s?|0)[67](\s?\d{2}){4}$/.test(v) ||
                "Veuillez saisir un numéro de téléphone valide",
            ],

            adresseMailRules: [
                (v) => !!v || "Vous devez saisir une adresse email",
                (v) =>
                /.+@.+\..+/.test(v) || "Veuillez saisir une adresse email valide",
            ],

            messageRules: [
                (v) => !!v || "Vous devez saisir un message",
                (v) =>
                (v && v.length <= 200) ||
                " Ce champ doit comporter moins de 200 caractères",
            ],

            //--------------------------------//

            formulaire: {
                nomPrenom: "",
                adresseMail: "",
                numeroTelephone: "",
                message: "",
            },
            items: [
                ["Le produit"],
                ["Fonctionnalité"],
                ["Tarification"],
                ["Avis"],
                ["Contact"],
            ] /** TODO: lien de menu-drawer  */ ,
            menuItems: [
                { title: "Le produit", path: "/home" },
                { title: "Fonctionnalités", path: "/fonction" },
                { title: "Tarification", path: "/tarification" },
                { title: "Avis", path: "/avis" },
                { title: "Contact", path: "/contact" },
                { title: "linkedin", path: "", icon: "mdi-linkedin" },
            ],

            logos: [
                { src: require("../assets/logo_slider/carrefour.png") },
                { src: require("../assets/logo_slider/casino.png") },
                { src: require("../assets/logo_slider/franprix.png") },
                { src: require("../assets/logo_slider/vival.png") },
            ],
            Croissant: [{
                    text: "Frais de livraison et de mise en service inclus",
                    icon: "mdi-checkbox-blank-circle-outline",
                },
                {
                    text: "Disponible sur vos événements courts",
                    icon: "mdi-checkbox-blank-circle-outline",
                },
                {
                    text: "Un matériel testé et fonctionnel à tous les coups",
                    icon: "mdi-checkbox-blank-circle-outline",
                },
                {
                    text: "Ne vous souciez plus du stockage ni de la logistique",
                    icon: "mdi-checkbox-blank-circle-outline",
                },
            ],
            testimoniales: [{
                    src: require("../assets/avis/customer1.webp"),
                    name: "Jonathan Doe",
                    job: "Directeur du groupe Mamouth",
                    text: "Je n'ai jamais bu un jus d'orange aussi juteux ! Et cette offre de service! Incroyable! ",
                },
                {
                    src: require("../assets/avis/customer2.webp"),
                    name: "Jessica Nulloy",
                    job: "Hotesse de caisse chez Norma",
                    text: "Mes clients ne craignent plus le coronavirus depuis qu'ils peuvent boire de délicieux jus de fruits.",
                },
                {
                    src: require("../assets/avis/customer3.webp"),
                    name: "Alex Dutillo",
                    job: "DAF chez Prix Unique",
                    text: "profile Je n'ai jamais bu un jus d'orange aussi juteux ! Et cette offre de service! Incroyable! ",
                },
                {
                    src: require("../assets/avis/customer4.webp"),
                    name: "Nicolas Brundo",
                    job: "Directeur régional Intermarché",
                    text: " J'ai implanté ces machines dans mes supermarchés, les clients l'adore.",
                },
            ],

            Pain: [{
                    text: "Une fois que nos appareils ont terminé de vous rendre service, nous les auditons et reconditionnons dans nos ateliers.",
                    icon: "mdi-checkbox-blank-circle-outline",
                },
                {
                    text: 'Bichonnés et remis en état, nos équipements retournent au travail et servent d autres clients dans le cadre de notre programme "Second Life".',
                    icon: "mdi-checkbox-blank-circle-outline",
                },
                {
                    text: "Après de nombreuses années de loyaux services chez nos clients, lorsqu il n est plus possible d assurer les exigences de qualité et de sécurité, nos équipements sont pris en charge pour leur recyclage dans une filière agréée mettant en avant les aspects sociaux et environnementaux.",
                    icon: "mdi-checkbox-blank-circle-outline",
                },
            ],

            slides: [{
                    src: "@/assets/avis/customer4.webp",
                    text: "Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?",
                    title: "Nicolas Brundo",
                    subtitle: "Directeur régional Intermarché",
                },
                {
                    src: "@/assets/avis/customer4.webp",
                    text: "Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?",
                    title: "Johnathan Doe",
                    subtitle: "Directeur général du groupe Mamouth",
                },
                {
                    src: "@/assets/avis/customer4.webp",
                    text: "Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?",
                    title: "Jessica Nulloy",
                    subtitle: "Hotesse de caisse chez Norma",
                },
                {
                    src: "@/assets/avis/customer4.webp",
                    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
                    title: "Alex Duttilo",
                    subtitle: "DAF chez prisuniq",
                },
                {
                    src: "@/assets/avis/customer4.webp",
                    text: "Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?",
                    title: "Nicolas Brundo",
                    subtitle: "Directeur régional Intermarché",
                },
            ],

            colors: ["four"],
        };
    },

    computed: {
        size() {
            const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
                this.$vuetify.breakpoint.name
            ];
            return size ? {
                [size]: true,
            } : {};
        },
    },

    methods: {
        created: function() {
            setInterval(() => {
                this.show = !this.show;
            }, 2000);
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },

        sendMail(message) {
            if (this.valid) {
                if (message) {
                    this.formulaire.message = message;
                }
                console.log("this.sendMail", this.formulaire);
                emailjs
                    .send(
                        "service_5hye3gr",
                        "template_tcz94j2",
                        this.formulaire,
                        "user_mHRxLwhvwf7v3lNnUhrjX"
                    )
                    .then(
                        (result) => {
                            this.textDialog =
                                "Votre demande a bien été envoyée ! Merci pour votre intêret.";
                            this.dialog = true;
                            console.log("SUCCESS!", result.text);
                        },
                        (error) => {
                            this.textDialog =
                                "Votre demande n'a pas été envoyé ! Merci de réessayer dans quelques minutes.";
                            this.dialog = true;
                            console.log("FAILED...", error.text);
                        }
                    );
            }
        },

        submit() {
            this.$v.$touch();
        },

        validate() {
            this.$refs.form.validate();
        },
    },
};
</script>

<style scoped>

.zoomable-
{
     transition: transform 1s;
}

.zoomable-:hover { 
    transform: scale(1.6); 
}

.zoomable
{
     transition: transform 1s;
}

.zoomable:hover { 
    transform: scale(1.3); 
}
.logo {
    width: 50%;
}

.carrousel-logo {
    max-width: 10%;
}

a {
    text-decoration: none;
}

.contactez {
    background-image: url("../assets/img-rotisserie/meat-texture-while-grilling.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 1024px) {
    .logo {
        width: 40%;
    }
}

@media screen and (max-width: 810px) {
    .gala {
        width: 65%;
    }
    .carrousel-logo {
        max-width: 25%;
    }
}

@media screen and (max-width: 425px) {
    .carrousel-logo {
        max-width: 25%;
    }
}
</style>
